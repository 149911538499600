"use client";

import googleSvg from "@/images/Google.svg";
import twitterSvg from "@/images/Twitter.svg";
import facebookSvg from "@/images/Facebook.svg";

import * as yup from "yup";
import Link from "next/link";
import Image from "next/image";
import React, { useState } from "react";
import { signIn } from "next-auth/react";
import Input from "@/shared/Input/Input";
import { useForm } from "react-hook-form";
import { useRouter } from "next/navigation";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const schema = yup
  .object({
    email: yup.string().email().required("ce champs est requis"),
    password: yup.string().min(8).required("ce champs est requis"),
  })
  .required();

const PageLogin = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const router = useRouter();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (data: any) => {
    setError(false);
    setLoading(true);
    const response = await signIn("credentials", {
      body: JSON.stringify({
        email: data.email,
        password: data.password,
      }),
      redirect: false,
    });
    setLoading(false);

    if (response?.error) {
      setError(true);
      setErrorMessage(response?.error);
      return;
    }

    router.push("/");

    console.log(response);
  };

  return (
    <div className={`nc-PageLogin`} data-nc-id="PageLogin">
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Connection
        </h2>

        <div className="max-w-md mx-auto space-y-6">
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <Image
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                  sizes="40px"
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}

          {error && (
            <Alert variant="destructive">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}

          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Adresse mail
              </span>
              <Input
                type="email"
                className="mt-1"
                autoComplete="false"
                {...register("email")}
                placeholder="example@example.com"
              />
              <p className="text-sm text-red-700">{errors.email?.message}</p>
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link href="/forgot-pass" className="text-sm text-green-600">
                  mot de passe oublier?
                </Link>
              </span>
              <Input
                type="password"
                className="mt-1"
                {...register("password")}
              />
              <p className="text-sm text-red-700">{errors.password?.message}</p>
            </label>
            <ButtonPrimary type="submit" loading={loading}>
              Continuer
            </ButtonPrimary>
          </form>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            <Link className="text-green-600" href="/signup">
              Inscrivez-vous
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
